.wrapper { 
  padding-top: 30px; 
  } 
  
  body h3 { 
  margin-bottom: 25px; 
  } 
  
  .navbar-brand a { 
  color: #ffffff; 
  } 
  
  .form-wrapper, 
  .table-wrapper { 
  max-width: 500px; 
  margin: 0 auto; 
  } 
  
  .table-wrapper { 
  max-width: 700px; 
  } 
  
  .edit-link { 
  padding: 7px 10px; 
  font-size: 0.875rem; 
  line-height: normal; 
  border-radius: 0.2rem; 
  color: #fff; 
  background-color: #28a745; 
  border-color: #28a745; 
  margin-right: 10px; 
  position: relative; 
  top: 1px; 
  } 
  
  .edit-link:hover { 
  text-decoration: none; 
  color: #ffffff; 
  } 
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button, 
  input::-webkit-inner-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
  } 
  
  /* Firefox */
  
  input[type=number] { 
  -moz-appearance: textfield; 
  }
  
  
  .cases-dropdown {
    padding-top: 20px; /* Add some space above the dropdown */
  }

  textarea {
    width: 90%;  /* Takes up 80% of the parent container's width */
    height: 30vh;  /* 20% of the viewport height */
  }